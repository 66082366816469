import watchMyTimeImage from '../assets/Image/Projet/WatchMyStyle/coverImage.png'
import originalComicsImage from '../assets/Image/Projet/OriginalComics/coverImage.png'
import tanaguruImage from '../assets/Image/Projet/Tanaguru/coverImage.png'

export const projectList = [
  {
    id: 'watchMyStyle',
    name: 'Watch my Style',
    title: "Watch my style - Design & developpement d'un site marchant",
    date: '2024',
    crew: 'Seul sur le projet',
    duration: '2 mois',
    tags: ['UI/UX', 'Design graphique', 'Développement', 'Site web'],
    description: `La société Lunettes de Luxe, spécialisée dans la vente de lunettes
            de soleil haut de gamme, réalise la majorité de ses ventes via son
            site e-commerce.`,
    image: watchMyTimeImage,
  },

  {
    id: 'originalComics',
    name: 'Original comics',
    title: 'Original Comics - Refonte d’un site web',
    date: '2023',
    duration: '4 mois',
    crew: 'Seul sur le projet',
    tags: ['UI/UX', 'Site web', 'Recherche utilisateur', 'Tests utilisateurs'],
    description: `Conception d’un site web pour une librairie spécialisée dans la bande dessinée. Face à une augmentation des plaintes concernant le site et souhaitant mettre en valeur son fonds de catalogue, l'entreprise se trouve devant un défi.`,
    image: originalComicsImage,
  },
  {
    id: 'tanaguru',
    name: 'Tanaguru',
    title: "Tanaguru - Design d'un outil d'audit d'accessibilité",
    date: '2023',
    duration: '6 mois',
    crew: '4 personnes',
    tags: ['UI/UX', 'Design graphique', 'Développement', 'Site web'],
    description: `Conception d'un outil spécialisé dans les audits d'accessibilité pour le compte de Tangaguru, avec une prise en compte continue des enjeux liés à l'accessibilité tout au long du projet.`,
    image: tanaguruImage,
  },
]
